<template>
    <div class="nav_box">
      <div class="nav justify-end">
        <div class="nav_loge"></div>
        <ul class="nav_list">
          <li class="nav_list_li" v-for="val in NavList" :key="val.id" :data-to="val.id">
            {{ val.name }}
          </li>
        </ul>
      </div>
    </div>
  </template>
  <style scoped>
  .nav_box {
    width: 100%;
    height: 136PX;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF !important;
    box-shadow: 0px 1px 0px 0px #EFEFEF;

  }
  .nav {
    width: 1200PX;
    height: 60PX;
    align-items: center;
    z-index: 99999;
  }
  .nav_loge {
    width: 169PX;
    height: 32PX;
    background: url("../../assets/byteteade_logo1.png") no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: auto;
  }
  .nav_list {
    display: flex;
  }
  .nav_list_li {
    position: relative;
    margin-right: 64PX;
    font-size: 16PX;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #151515;
    cursor: pointer;
    height: 30PX;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
  }
  .nav_list_li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    border-bottom: 2PX solid #fff;
    transition: 0.2s all linear;
  }
  .nav_list_li:hover::before {
    border-bottom: 2PX solid #fff;
    width: 100%;
    top: 0;
    left: 0;
    transition-delay: 0.1s;
  }
  </style>
  <script>
  import { ref } from "vue";
  
  export default {
    name: "nav",
    components: {},
  
    setup() {
      let NavList = ref([
        {
          name:"ByteTrade OS",
          id:'toByteTradeOS'
        },
        {
          name:"Ecosystem",
          id:'toEcosytem'
        },
        {
          name:"Event",
          id:'toEvent'
        },
        {
          name:"Research",
          id:'toResearch'
        },
        {
          name:"Contact",
          id:'toContact'
        },
        
      ]);
      return {
        NavList,
        myImg:'../../assets/nav-box.jpg'
      };
    },
  
    props: {
      msg: String,
    },
  
    methods: {
      skipTo (selector) {
        const navHeight = document.querySelector('.nav').offsetHeight
        const target = document.getElementById(selector)
        target.scrollIntoView({ behavior: 'smooth' })
        window.scrollTo(0, target.offsetTop - navHeight)-160
      }
    }
  };
  </script>
  