import {createRouter,createWebHistory} from "vue-router";

import Confirmation from "../components/confirmation/index.vue";
import Home from '../components/index.vue'

const routes=[
    {path:"/",name:"home",component:Home},

    {path:"/confirmation",name:"confirmation",component:Confirmation},
];
const router=createRouter({
    history:createWebHistory(),
    routes
 
})
export default router;