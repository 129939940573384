<template>
    <iPhoneHome v-if="_isMobile()" />
    <PcHome v-else/>
</template>
  
  <script>
  
  import iPhoneHome from '../components/iPhone/home.vue';
  import PcHome from '../components/pc/home.vue';
  import '../assets/fonts/font.css';
  import '../style/animation.css'
  
  export default {
    name: 'App',
    components: {
      PcHome,
      iPhoneHome,
    },
    setup() {
      return {
          isPhone:false,
      };
    },
    methods: {
      _isMobile() {
        let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        return flag;
      }
    },
    
  }
  </script>