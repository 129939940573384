<template>
  <div class="footer_box" id="toContact">
    <div class="footer_auto">
      <div class="subscribe_box">
        <div class="subscribe_newsletter">
          <p class="subscribe_newsletter_p">Subscribe Newsletter</p>
          <div id="mc_embed_signup">
            <form
              action="https://app.sendx.io/api/v1/form/9b5eAFz1LmyKbKUbd1EspB" 
              method="post"
              id="mc-embedded-subscribe-form"
              name="mc-embedded-subscribe-form"
              class="validate"
              ref="formRef"
              target="form" 
              @submit.prevent="onSubmit"

            >
              <div id="mc_embed_signup_scroll">
                <div class="mc-field-group">
                  <input type="Email" v-model="inputTxt" name="Email" class="required email" placeholder="Type your e-mail here" id="mce-EMAIL" required>
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
                  <input type="submit" value="" id="mc-embedded-subscribe" class="button">
                  <div class="button_btn"></div>
                </div>
              </div>
            </form>

            <iframe name="form" id="form" ref="iframeRef" style="display: none;" onload=""></iframe>
          </div>
        </div>
      </div>
      <div class="footer_logo footer_bakcgr"></div>
      <div class="footer_logo_bottom">
        <a href="https://twitter.com/BytetradeLab">
          <div class="footer_logo_but footer_bakcgr footer_img"></div>
        </a>
        <a href="https://medium.com/@bytetradelab">
          <div class="footer_logo_but footer_bakcgr footer_image"></div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { message } from 'ant-design-vue';

export default {
  name: "footer",
  components: {},

  setup() {
    const iframeRef = ref(null);
    const inputTxt = ref(null);
    const formRef = ref(null);
    const isSubmit = ref(false);
    const key = 'Subscribing';

    const onSubmit = () => {
      if (!inputTxt.value) return false;
      if (isSubmit.value) {
        return false;
      } else {
        isSubmit.value = true;
      }
      
      formRef.value.submit();
      message.loading({ content: 'Subscribing...', key, duration: 600});
    }

    const checkIframe = () => {
      if (!isSubmit.value) {
        return false;
      } else {
        isSubmit.value = false;
      }
      message.success({ content: 'You have successfully subscribed to our newsletter :)', key, duration: 2 });
      inputTxt.value = '';
    }


    onMounted(() => {
      if (iframeRef.value.attachEvent) {
        // IE
        iframeRef.value.attachEvent('onload', () => {
          checkIframe();
        });
      } else {
        // not IE
        iframeRef.value.onload = function () {
          checkIframe();
        };
      }
    })

    
    return {
      iframeRef,
      inputTxt,
      formRef,
      onSubmit
    };
  }
};
</script>


<style scoped>
.footer_bakcgr {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.footer_box {
  width: 100%;
  background: #ec4b3d;
  padding-bottom: 40px;
}
.footer_auto {
  width: 344px;
  height: 100%;
  margin: 0 auto;
  padding-top: 44px;
}
.footer_logo {
  width: 127px;
  height: 24px;
  background-image: url("../../assets/iphone/logo.png");
  display: flex;
}
.footer_logo_but {
  width: 42px;
  height: 42px;
}
.footer_img {
  background-image: url("../../assets/iphone/trese.png");
}
.footer_image {
  background-image: url("../../assets/iphone/sou.png");
  margin-left: 24px;
}
.footer_logo_bottom {
  display: flex;
  margin-top: 41px;
}
.subscribe_newsletter_p {
  font-size: 20px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #ffffff;
}
.required {
  width: 340px;
  height: 47px;
  border-radius: 1px;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-family: Montserrat-Regular, Montserrat;
  font-weight: 400;
  color: #ffffff;
  padding-left: 16px;
}
.required::-webkit-input-placeholder{
  color: #FFFFFF;
  opacity: 60%;
}
.required::-moz-placeholder{   
  color: #FFFFFF;
  opacity: 60%;
}
.required:-moz-placeholder{    
  color: #FFFFFF;
  opacity: 60%;
}
.required:-ms-input-placeholder{  
  color: #FFFFFF;
  opacity: 60%;
}
.button {
  width: 64px;
  height: 48px;
  border-radius: 1px;
  position: absolute;
  top: 0px;
  right: 4px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 99;
  opacity: 0;
}
.button_btn {
  width: 64px;
  height: 48px;
  border-radius: 1px;
  position: absolute;
  top: 0px;
  right: 4px;
  background-image: url("../../assets/Subscribe.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.mc-field-group {
  position: relative;
  top: 32px;
}
.subscribe_box {
  height: 204px;
}
</style>
