<template>
    <div class="tips_box" ref="tips_headers">
        <div class="tips_content flex-col">
            <div class="tips_img">
                <div :class="tips_Animate ? 'tips_img_scele backge_img':'tips_img_scele backge_img'"></div>
                <div class="tips_img_loge backge_img">
                    <div :class="tips_Animate ? 'tips_img_loge_1 backge_img slide-in-left-text':'tips_img_loge_1 backge_img'"></div>
                    <div :class="tips_Animate ? 'tips_img_loge_2 backge_img text-focus-2-in':'tips_img_loge_2 backge_img'"></div>
                    <div :class="tips_Animate ? 'tips_img_loge_3 backge_img slide-in-right':'tips_img_loge_3 backge_img'"></div>
                </div>
            </div>
            <div :class="tips_Animate ? 'tips_text fade-in-bottom-text':'tips_text'">
                <p>At ByteTrade, we are actively involved in incubating and early stage investing of Web 3.0 projects to build ByteTrade Web3 OS Ecosystem. </p>
                <p>We aim to enable the builders of Web 3.0 by providing a variety of resources to them including but not limited to: </p>
                <p>technology expertise, product definition, business planning, GTM strategies and funding.</p>
            </div>
        </div>
    </div>
</template>
<style scoped>
.tips_box {
    width: 100%;
    height: 480PX;
    background: #EC4B3D;
}
.tips_content {
    width: 1200PX;
    height: 100%;
    margin: 0 auto;
    justify-content: center;
}
/* tips_img */
.backge_img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.tips_img {
   height: 288PX;
}
.tips_img_scele {
    width: 144PX;
    height: 145PX;
    position:relative;
    top: 93PX;
    left: 404PX;
    z-index:9;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out
}
.tips_img_scele:hover{
    transform: rotate(30deg);
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
}
.tips_img_loge {
    width: 556PX;
    height: 188PX;
    /* background-image: url('../../assets/tips.png'); */
    position: relative;
    top: -93PX;
    left: 0PX;
}
.tips_img_loge_1 {
    width: 388PX;
    height: 80PX;
    background-image: url('../../assets/Bytetrade_img.png');
}
.tips_img_loge_2 {
    width: 182PX;
    height: 187PX;
    background-image: url('../../assets/img_san.png');
    position: absolute;
    top: -25PX;
    left: 260PX;
}
.tips_img_loge_3 {
    width: 168PX;
    height: 61PX;
    background-image: url('../../assets/ECO.png');
    position: absolute;
    top:80PX;
    left: 345PX;
}
.tips_text {
    font-size: 16PX;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #FFFFFF;
}
</style>
<script>
import { ref,onMounted,onBeforeUnmount } from "vue";
import { isElementNotInViewport } from "../../common/utils";

export default {
  name: 'explain',
  components: {
  },
  
  setup() {
    let tips_Animate = ref(false);
    let tips_headers = ref(null)
    const tipsScrollToTop = () => {
        !isElementNotInViewport(tips_headers.value) ? tips_Animate.value = true: tips_Animate.value = false;
    };
    onMounted(()=>{
        // 监听滚动事件
        window.addEventListener("scroll",tipsScrollToTop);
    })
    onBeforeUnmount(()=>{
        //移除滚动事件
        window.removeEventListener('scroll',tipsScrollToTop);
    })
    return {
        tips_Animate,
        tips_headers,
    }
  },
}
</script>