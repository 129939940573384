<template>
  <div class="resea_box" id="toResearch">
    <div class="resea_auto">
      <div class="Research_text">Research</div>
      <div class="Research_All">
        <a-pagination
          class="pagination"
          v-model:page-size="pageSizes"
          v-model:current="current"
          @change="onChange"
          simple
          :total="feedList.length"
        />
        <a href="https://medium.com/@bytetradelab" class="research_All_text">
          All
        </a>
      </div>
      <div class="Research_article" v-for="itme in feedData" :key="itme">
        <a :href="itme.link" class="Research_article" style="border: none">
          <div class="Research_article_text">
            <div class="Research_article_top">
              <span class="Research_article_span"></span>
              <span class="Research_article_Bytetrade">{{ itme.author }}</span>
              <span class="Research_article_data left">{{DoDateTime(getCtx.$moment(itme.pubDate).format('M'))}}</span>
              <span class="Research_article_data left_data">{{getCtx.$moment(itme.pubDate).format('DD')}}</span>
            </div>
            <p class="Research_article_innovations">
              {{ itme.title }}
            </p>
            <div class="event_center_imgs"></div>
          </div>
          <div
            class="link_img"
            :style="{ backgroundImage: 'url(' + itme.thumbnail + ')' }"
          ></div>
        </a>
      </div>
    </div>
  </div>
</template>
<style scoped>
.resea_box {
  width: 100%;
  height: auto;
}
.resea_auto {
  width: 344px;
  border-right: 2px solid #efefef;
  border-left: 2px solid #efefef;
  margin: 0 auto;
  overflow: hidden;
}
.Research_text {
  font-size: 32px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #333333;
  height: 120px;
  line-height: 120px;
  padding-left: 14px;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.Research_All {
  width: 100%;
  display: flex;
  align-items: center;
  height: 99px;
}
/deep/ .anticon svg {
  display: none;
}
/deep/ .anticon {
  width: 14px;
  height: 14px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../assets/Arrow.svg");
  margin-top: 5px;
}
/deep/ .ant-pagination-disabled {
  opacity: 60%;
}
/deep/ .anticon-left {
  transform: rotate(180deg);
}
.pagination {
  margin-left: 18px;
}
.research_All_text {
  margin-left: auto;
  margin-right: 18px;
  font-size: 16px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
  border-bottom: 1px solid #333333;
}
.event_center_imgs {
  width: 16px;
  height: 16px;
  margin-top: 16px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../assets/set.png");
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.Research_article {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #efefef;
  padding-left: 14px;
  padding-right: 12px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.link_img {
  width: 56px;
  height: 56px;
  margin-left: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.Research_article_text {
  width: 230px;
}
.Research_article_top {
  display: flex;
  align-items: center;
}
.Research_article_span {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: #151515;
  border-radius: 50%;
}
.Research_article_Bytetrade {
  font-size: 16px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #333333;
  margin-left: 6px;
}
.Research_article_data {
  font-size: 16px;
  font-family: Montserrat-Regular, Montserrat;
  font-weight: 400;
  color: #74798e;
  
}
.left {
    margin-left: 13px;
}
.left_data {
    margin-left: 6px;
}
.Research_article_innovations {
  font-size: 16px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #333333;
  margin-top: 16px;
}
</style>
<script>
import { onMounted, ref, getCurrentInstance } from "vue";

export default {
  name: "researchIphone",
  setup() {
    const currentInstance = getCurrentInstance();
    const getCtx = currentInstance.appContext.config.globalProperties;

    const current = ref(1);
    const feedList = ref([]);
    const feedData = ref([]);
    const pageSizes = ref(3);
    const pageSizeRef = ref(3);
    const pageNum = ref(0);
    const totalPage = ref([]);

    const getIn = () => {
      getCtx.$httpAxios
        .get(
          `https://api.rss2json.com/v1/api.json?rss_url=${"https://medium.com/feed/@bytetradelab"}`
        )
        .then((response) => {
          if (response.data.status != "ok") {
            throw response.message;
          }
          response.data.items.forEach((item) => {
            console.log(item);
            feedList.value.push(item);
          });

          for (
            let i = 0;
            i < Math.ceil(feedList.value.length / pageSizes.value);
            i++
          ) {
            totalPage.value[i] = feedList.value.slice(
              pageSizes.value * i,
              pageSizes.value * (i + 1)
            );
          }
          feedData.value = totalPage.value[0];
        });
    };
    const onChange = (current, size) => {
      console.log(current, size);
      feedData.value = totalPage.value[current - 1];
    };
    const DoDateTime = (data)=> {
      let m=new Array("Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Spt","Oct","Nov","Dec"); 
      return m[data -1]
    }
    onMounted(() => {
      getIn();
    });
    return {
      current,
      feedList,
      getCtx,
      pageSizes,
      pageSizeRef,
      onChange,
      pageNum,
      totalPage,
      feedData,
      DoDateTime
    };
  },
};
</script>
