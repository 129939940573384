<template>
    <div class="project_box" id="toEcosytem">
        <div class="project_auto">
            <div class="project_logo"></div>
            <p class="project_p">At ByteTrade, we are actively involved in incubating and early stage investing of Web 3.0 projects to build ByteTrade Web3 OS Ecosystem. </p>
            <p class="project_p">We aim to enable the builders of Web 3.0 by providing a variety of resources to them including but not limited to: technology expertise, product definition, business planning, GTM strategies and funding.</p>
        </div>
    </div>
</template>
<style scoped>
.project_box {
    width: 100%;
    height: 497px;
    background: #EC4B3D;
}
.project_auto {
    width: 344px;
    height: 100%;
    margin: 0 auto;
    padding-top: 63px;
}
.project_logo {
    width: 235px;
    height: 91px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url('../../assets/iphone/eco.png');
    margin-bottom: 28px;
}
.project_p {
    width: 306px;
    font-size: 15px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 28px;
}
</style>