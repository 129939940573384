<template>
  <div class="research_box" id="toResearch">
    <div class="research_auto">
      <div class="research_title">
        <p class="research_title_text">Research</p>
        <div class="research_title_pages">
          <a-pagination v-model:page-size="pageSizes" v-model:current="current" @change="onChange" simple :total="feedList.length" />
          <a href="https://medium.com/@bytetradelab">
          <div class="research_All">
             All
          </div>
        </a>
        </div>
      </div>
      <div class="research_content" v-for="itme in feedData" :key="itme">
        <a :href="itme.link" class="research_content">
        <div class="research_content_set">
          <div class="research_content_lab">
            <span class="research_content_circular"></span>
            <span class="research_content_title">{{itme.author}}</span>
            <span class="research_content_date left">{{DoDateTime(getCtx.$moment(itme.pubDate).format('M'))}}</span>
            <span class="research_content_date left_data">{{getCtx.$moment(itme.pubDate).format('DD')}}</span>
          </div>
          <h5 class="research_redefining">{{itme.title}}</h5>
          <p class="research_backed">
            {{HTMLEncode(itme.description)}}
          </p>
          <div class="event_center_imgs"></div>
        </div>
        <div class="research_img" :style="{backgroundImage:'url('+itme.thumbnail+')'}" ></div>
      </a>
      </div>
      
    </div>
  </div>
</template>
<style scoped>
.research_box {
  width: 100%;
  height: auto;
}
.research_auto {
  width: 1198PX;
  height: 100%;
  border-left: 1PX solid #efefef;
  border-right: 1PX solid #efefef;
  margin: 0 auto;
}
.event_center_imgs {
  width: 16PX;
  height: 16PX;
  margin-top: 10PX;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../assets/set.png");
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.event_center_imgs:hover {
  transform: translateX(10PX);
  -webkit-transform: translateX(10PX);
  -moz-transform: translateX(10PX);
  -o-transform: translateX(10PX);
  -ms-transform: translateX(10PX);
}
.research_title {
  width: 100%;
  height: 163PX;
  border-top: 1PX solid #efefef;
  display: flex;
  align-items: center;
}
.research_title_text {
  font-size: 44PX;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #333333;
  margin-left: 39PX;
  margin-bottom:0PX;
}
.research_title_pages {
  margin-left: auto;
  margin-right: 48PX;
  display: flex;
  align-items: center;
}
.research_All {
  font-size: 16PX;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #333333;
  cursor: pointer;
  margin-left: 28PX;
}
.research_All:hover{
  border-bottom: 1PX solid #333333;
}
.ant-pagination {
    font-size: 17PX;
}
.research_img {
  width: 240PX;
  height: 240PX;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: 48PX;
}
.research_content {
  width: 100%;
  height: 320PX;
  display: flex;
  align-items: center;
  border-top: 2PX solid #efefef;
  padding: 4PX;
}
.research_content_set {
  width: 710PX;
  margin-left: 39PX;
}
.research_content_circular {
  width: 24PX;
  height: 24PX;
  background: #151515;
  border-radius: 50%;
  display: inline-block;
}
.research_content_title {
  font-size: 16PX;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #333333;
  margin-left: 6PX;
}
.research_content_date {
  font-size: 16PX;
  font-family: Montserrat-Regular, Montserrat;
  font-weight: 400;
  color: #74798e;
}
.left_data {
  margin-left: 6PX;
}
.left {
  margin-left: 20PX;
}
.research_content_lab {
  display: flex;
  align-items: center;
}
.research_redefining {
  font-size: 24PX;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #333333;
  margin-top: 8PX;
  margin-bottom: 10PX;
}
.research_backed {
  width: 100%;
  height: 65px;
  font-size: 16PX;
  font-family: Montserrat-Regular, Montserrat;
  font-weight: 400;
  color: #333333;
  overflow: hidden;
  display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 4;
}
.pages {
  display: flex;
  align-items: center;
}
.input_text {
  width: 52PX;
  height: 26PX;
  border-radius: 4PX;
  border: 1PX solid #c8c5d4;
  overflow: hidden;
}

.text {
  width: 52PX;
  height: 26PX;
  line-height: 26PX;
  text-align: center;
}
/deep/ .anticon svg {
  display: none;
}
/deep/ .anticon {
  width: 16PX;
  height: 16PX;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../assets/Arrow.svg');
}
/deep/ .ant-pagination-disabled {
  opacity: 60%;
}
/deep/ .anticon-left {
  transform: rotate(180deg);
}

</style>
<script>
import {onMounted,ref,getCurrentInstance } from "vue";

export default {
  name: "research",
  setup() {
    const currentInstance = getCurrentInstance()
    const getCtx = currentInstance.appContext.config.globalProperties;

    const current = ref(1);
    const feedList = ref([])
    const feedData = ref([])
    const pageSizes = ref(3);
    const pageSizeRef = ref(3);
    const pageNum = ref(0)
    const totalPage = ref([])


    const HTMLEncode = (text)=> {
      let temp = document.createElement("div");
      temp.innerHTML = text;
      let output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    }
    const getIn = () => {
      
      getCtx.$httpAxios.get(`https://api.rss2json.com/v1/api.json?rss_url=${'https://medium.com/feed/@bytetradelab'}`).then( (response)=> {
          if(response.data.status != 'ok'){ throw response.message; }
          response.data.items.forEach(item => {
            feedList.value.push(item);
          });

          for (let i = 0; i < Math.ceil(feedList.value.length / pageSizes.value); i++) {
            totalPage.value[i] = feedList.value.slice(pageSizes.value * i, pageSizes.value * (i + 1))
          }
          feedData.value = totalPage.value[0]
      });      
    }

   
    
    const onChange = (current, size) => {
      console.log(current, size);
      feedData.value = totalPage.value[current - 1]
    }
    const DoDateTime = (data)=> {
      let m=new Array("Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Spt","Oct","Nov","Dec"); 
      return m[data -1]
    }
    onMounted(() => {
      getIn()
    });
    return {
        current,
        feedList,
        getCtx,
        HTMLEncode,
        pageSizes,
        pageSizeRef,
        onChange,
        pageNum,
        totalPage,
        feedData,
        DoDateTime
    };
  },
};

</script>
