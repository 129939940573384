<template>
  <div class="nav_page Nav_background">
      <div class="nav_page_loge">
          <div class="nav_page_loge_img Nav_background"></div>
          <div @click="clickDownMenu" class="nav_page_list Nav_background"></div>
      </div>
    <div class="nav_image">
        <div class="nav_image_one Nav_background"></div>
        <div class="nav_image_two Nav_background"></div>
        <div class="nav_image_text">
            <p class="nav_image_p">ByteTrade</p>
            <p class="nav_image_lab">
                <span class="nav_image_lab_sp">Web3.0<br /> Infrastructure<br /> Innovator and Builder to Return<br /> Data Ownership Back to Users.</span>
                <span class="nav_image_lab_span">Lab.</span>
            </p>
        </div>
    </div>
    <DownMenu ref="childsDom" />
  </div>
</template>
<style scoped>
.Nav_background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.nav_page {
    width: 100%;
    height: 548px;
    background-image: url('../../assets/iphone/phone.png');
    background-size: 100% 548px;

}
.nav_page_loge {
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 999;
}
.nav_page_loge_img {
   width: 127px;
   height: 24px;
   background-image: url('../../assets/iphone/logo.png');
   margin-left: 20px;
}
.nav_page_list {
    margin-left: auto;
    width: 32px;
    height: 32px;
    background-image: url('../../assets/iphone/nav_list.png');
    margin-right: 12px;
}
.nav_image {
    position: relative;
}
.nav_image_one {
    width: 355px;
    height: 387px;
    background-image: url('../../assets/iphone/nav-con.png');
    position: absolute;
    top: 0px;
    left: 20px;
    z-index: 9;
}
.nav_image_two {
    width: 320px;
    height: 359px;
    background-image: url('../../assets/iphone/nav_bak.png');
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 99;
}
.nav_image_text {
    position: absolute;
    top:237px;
    left: 0px;
    z-index: 999;
    width: 100%;
    
}
.nav_image_p {
    font-size: 52px;
    font-family:Chillax-Variable;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    width: 100%;
}
.nav_image_lab {
    display: flex;
    align-items: center;
    justify-items: center;


}
.nav_image_lab_span {
    font-size: 52px;
    font-family: Chillax-Variable;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    position: absolute;
    right: 59px;
    top: 52px;
}
.nav_image_lab_sp{
    font-size: 18px;
    font-family: Chillax-Variable;
    font-weight: 500;
    color: #FFFFFF;
    position: absolute;
    left: 52px;
    top: 80px;
}
</style>
<script>
import { ref } from "vue";
import DownMenu from './drop_down_menu.vue'

export default {
  name: 'home',
  components: {
    DownMenu,
  },
  
  setup() {
    const childsDom = ref(null);
    const clickDownMenu = () => {
      childsDom.value.handelClick();
    };
    return {
        clickDownMenu,
        childsDom,
    }
  },
}
</script>
