<template>
    <div class="bytetr_box" id="toByteTradeOS">
        <div class="bytetr_auto">
            <div class="bytetr_os bytetr_background"></div>
            <p class="bytetr_text">Everyone's <span class="bytetr_text_span">Personal Edge Node</span> to Web3.0</p>
        </div>
        
    </div>
</template>
<style scoped>
.bytetr_background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.bytetr_box {
    width: 100%;
    height: 240px;
}
.bytetr_auto {
    width: 344px;
    height: 100%;
    margin: 0 auto;
}
.bytetr_os {
    height: 120px;
    width: 275px;
    background-image: url('../../assets/iphone/osImg.png');
    margin-top: 31px;
}
.bytetr_text {
    width: 275px;
    font-size: 18px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #333333;
    margin-top: 24px;
}
.bytetr_text_span {
    font-size: 18px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #333333;
}
</style>