export const isElementNotInViewport = function(el) {
	if (el) {
		let rect = el.getBoundingClientRect();
        // console.log('rect',rect)
		return (
			rect.top >=
				(window.innerHeight || document.documentElement.clientHeight) ||
			rect.bottom <= 0
		);
	}
};