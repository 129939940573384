<template>
  <div class="event_box" id="toEvent">
    <div class="event_auto">
      <div class="events_title align-center">Upcoming Event</div>
      <div class="events_title_box" v-if="eventsList">
        <div class="event_list" v-for="list in eventsList" :key="list.id">
          <div class="event_center">
            <a :href="list?.events[0]?.url">
              <div
                class="event_center_img"
                :style="{ backgroundImage: 'url(' + list?.events[0]?.logo?.url + ')' }"
              ></div>
            </a>
            <ul class="event_center_ul">
              <a :href="list?.events[0]?.url">
                <li class="event_center_title"> {{ list?.events[0]?.name?.text }}</li>
              </a>
              <li class="event_center_time" v-if="list.address.city === 'Palo Alto'">{{ getCtx.$moment(list?.events[0]?.start.local).format('YYYY-MM-DD hh:mm A') }} - {{ getCtx.$moment(list?.events[0]?.end.local).format('hh:mm A') }}</li>

              <li class="event_center_time" v-else>{{ getCtx.$moment(list?.events[0]?.start.local).format('YYYY-MM-DD hh:mm A') }} - {{ getCtx.$moment(list?.events[0]?.end.local).format('YYYY-MM-DD hh:mm A') }}</li>
              <li class="event_center_set">{{list.address.city}}</li>
              <li class="event_center_Center">
                {{list.address.localized_address_display}}
              </li>
              <a :href="list?.events[0]?.url">
                <li class="event_center_imgs"></li>
              </a>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.event_box {
  width: 100%;
  height: auto;
}
.event_list {
  width: 598PX;
  height: 559PX;
  border: 1PX solid #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
}
.event_center_title {
  width: 480PX;
  font-size: 24PX;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 700;
  color: #333333;
  line-height: 34PX;
}
.event_center_time {
  font-size: 18PX;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #ec4a3c;
  line-height: 34PX;
}
.event_center_set {
  font-family: Montserrat-Regular, Montserrat;
  font-weight: 400;
  color: #333333;
  font-size: 16PX;
  line-height: 34PX;
}
.event_center_imgs {
  width: 16PX;
  height: 16PX;
  margin-top: 6PX;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../../assets/set.png");
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.event_center_imgs:hover {
  transform: translateX(10PX);
  -webkit-transform: translateX(10PX);
  -moz-transform: translateX(10PX);
  -o-transform: translateX(10PX);
  -ms-transform: translateX(10PX);
}
.event_center_Center {
  font-size: 16PX;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #333333;
  line-height: 34PX;
}

.event_center {
  width: 480PX;
  height: 420PX;
}
.event_auto {
  width: 1198PX;
  height: 100%;
  border-left: 1PX solid #efefef;
  border-right: 1PX solid #efefef;
  margin: 0 auto;
}
.events_title_box {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-height: 1100PX;
  overflow: hidden;
}
.event_center_img {
  width: 480PX;
  height: 240PX;
  margin-bottom: 24PX;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.events_title {
  width: 100%;
  height: 123PX;
  border-bottom: 1PX solid #efefef;
  border-left: 1PX solid #efefef;
  border-right: 1PX solid #efefef;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 700;
  color: #333333;
  font-size: 44PX;
  padding-left: 40PX;
}
</style>
<script>
import {onMounted,getCurrentInstance ,reactive } from "vue";


export default {
  name: "events",
  components: {},

  setup() {
    const currentInstance = getCurrentInstance()
    const getCtx = currentInstance.appContext.config.globalProperties;
    let eventsList =reactive([]) ;

    const getInTheatersData = () => {
    getCtx.$httpAxios.get(`https://bytetrade-eventbrite-api.bttcdn.com/v1/api/venues`).then((datas) => {
        if(datas.data.data) {
          eventsList.push(...datas.data.data);
          eventsList.reverse((a,b)=>b-a);
        }
    });
    }

    onMounted(() => {
      getInTheatersData();
    });
    return {
      eventsList,
      getCtx,
    };
  },
};
</script>
