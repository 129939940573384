<template>
    <div class="content_box">
        <div class="content_auto">
            <div class="content_explain" v-for="list in contentList" :key="list.title">
                <div class="content_explain_flex">
                    <h4 class="content_explain_h4">{{list.title}}</h4>
                    <h5 class="content_explain_h5">{{list.information}}</h5>
                    <p class="content_explain_p">{{list.contentParag}}</p>
                    <p class="content_explain_p">{{list.contentTwo}}</p>
                    <p class="content_explain_p" v-if="list.contentSan">{{list.contentSan}}</p>
                </div>
                <div class="content_explain_boder"></div>
                <div class="content_explain_img" :style="{backgroundImage:'url('+list.url+')'}"></div>
                <div class="content_explain_boder"></div>
            </div>
            <div class="content_explain_Peer">
                <p class="content_explain_p font-szie">Peer-to-Peer <br/>Instant Messaging</p>
                <div class="content__Peer_img"></div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.content_box {
    width: 100%;
    border-top: 1px solid #EFEFEF;
}
.font-szie {
  font-size: 32px !important;
  font-family: Montserrat-SemiBold, Montserrat !important;
  font-weight: 600 !important;
  color: #333333;
}
.content_auto {
    width: 344px;
    border-left: 1px solid #EFEFEF;
    border-right: 1px solid #EFEFEF;
    margin: 0 auto;
    padding-bottom: 62px;
}
.content_explain {
    width: 100%;
}
.content_explain_Peer {
    font-size: 32px;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 600;
    color: #333333;
    margin-left: 15px;
    margin-top: 31px;
}
.content_explain_p {
    margin-bottom: 48px;
}
.content_explain_boder {
    width:100%;
    border-bottom: 1px solid #EFEFEF;
}

.content_explain_img {
    width: 310px;
    height: 388px;
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

}
.content__Peer_img {
    width:326px;
    height: 713px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('../../assets/iphone/peer.png');
    margin-left: -7px;
}
.content_explain_flex {
    width: 313px;
    padding-top: 20px;
    margin-left: 13px;
    padding-bottom: 24px;
}
.content_explain_h4 {
    font-size: 32px;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 600;
    color: #333333;
}
.content_explain_h5 {
    font-size: 18px;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #333333;
    margin-top: 14px;
}
.content_explain_p {
    font-size: 15px;
    font-family: Montserrat-Regular, Montserrat;
    font-weight: 400;
    color: #333333;
    margin-top: 23px;
}
</style>
<script>
import { ref } from "vue";

export default {
  name: "nav",
  components: {},

  setup() {
    let contentList = ref([
      {
        url:require('../../assets/iphone/conter1.png'),
        title: "DID",
        information: "Personal Decentralized Identifier to Web3.0",
        contentParag:
          "- DIDs for individuals, groups, DAOs, entities or others.",
        contentTwo:
          "- Self generated and owned solely by the user. NO requirement for a centralized party.",
        contentSan:
          "- Easily searched for and found by in the network and therefore Nodes can establish direct communications.",
      },
      {
        url:require('../../assets/iphone/conter2.png'),
        title: "Storage",
        information: "Storage Hub for Personal Private Data",
        contentParag:
          "- Personal storage that is privately owned and accessible to users with private key only.",
        contentTwo: "- Personal private data can be cached and saved in Node.",
        contentSan:
          "- Hold user's Verifiable Credentials to interact with real life Apps and DApps.",
      },
      {
        url:require('../../assets/iphone/conter3.png'),
        title: "Computing",
        information: "Computer Kernel for Edge Computing",
        contentParag:
          "-  Run Apps that require high computational power in a decentralized way.",
        contentTwo:
          "- Node will conduct edge computing, operating in local environment with personal private data and interact with servers and blockchains.",
      },
      {
        url:require('../../assets/iphone/conter4.png'),
        title: "Blockchains",
        information: "Personal One-Pass-Node for Blockchains",
        contentParag:
          "-  Support multiple blockchains and become multi-chain portal.",
        contentTwo:
          "- Run on an open protocol and one can attest the other through blockchain-based DIDs.",
      },
    ]);
    return {
      contentList,
    };
  },

  props: {
    msg: String,
  },
};
</script>