<template>
    <div class="explain_box" id="toByteTradeOS" ref="headers">
        <div class="explain_auto flex-col">
            <div class="explain_img_con">
                <div :class="header_Animate ? 'explain_img_os text-focus-2-in':'explain_img_os'"></div>
                <div :class="header_Animate ? 'explain_img_os_os slide-in-right':'explain_img_os_os'"></div>
                <div :class="header_Animate ? 'explain_img slide-in-left-text': 'explain_img'"></div>
            </div>
            <p :class="header_Animate ? 'explain_text fade-in-bottom-text':'explain_text'">Everyone's <span class="explain_text_span">Personal Edge Node</span> to Web3.0</p>
        </div>
    </div>
</template>
<style scoped>
.explain_box {
    width: 100%;
    height: 455PX;
    
}
.explain_auto {
    width: 1200PX;
    height: 100%;
    justify-content: center;
    margin: 0 auto;
}
.explain_text {
    font-size: 24PX;
    font-family: Montserrat-Medium, Montserrat;
    font-weight: 500;
    color: #333333;
}
.explain_img {
    width: 388PX;
    height: 80PX;
    background: url('../../assets/explain_text.png') no-repeat;
    background-position: center;
    background-size: contain;
    position:absolute;
    top: 0PX;
    left: 0PX;
}
.explain_img_con {
   height: 233PX;
   position: relative;
   top: 0PX;
}
.explain_img_text {
    width: 144PX;
    height: 145PX;
    background: url('../../assets/yuan.png') no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: 25PX;
    left: 395PX;
    cursor: pointer;
    z-index:99;
    transform:translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(360deg) skew(0deg, 0deg)
}
.explain_img_os {
    width: 144PX;
    height: 145PX;
    background: url('../../assets/explain_text_os.png') no-repeat;
    background-position: center;
    background-size: cover;
    position: absolute;
    top: -25PX;
    left: 300PX;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    animation-timing-function: linear;
    z-index:9;
}
.explain_img_os_os {
    position: absolute;
    top: 55PX;
    left: 340PX;
    z-index:999;
    background-image: url('../../assets/OS_amin.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 114PX;
    height: 61px;
}
.explain_img_text:hover{
    -webkit-animation: rotate-out-21-diff 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.8s both;
    animation: rotate-out-21-diff 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.8s both;
}
</style>
<script>
import { ref,onMounted,onBeforeUnmount } from "vue";
import { isElementNotInViewport } from "../../common/utils";

export default {
  name: 'explain',
  components: {
  },
  
  setup() {
    let header_Animate = ref(false);
    let headers = ref(null)
    const scrollToTop = () => {
        !isElementNotInViewport(headers.value) ? header_Animate.value = true: header_Animate.value = false;
    };
    onMounted(()=>{
        // 监听滚动事件
        window.addEventListener("scroll",scrollToTop);
    })
    onBeforeUnmount(()=>{
        //移除滚动事件
        window.removeEventListener('scroll',scrollToTop);
    })
    return {
        header_Animate,
        headers,
    }
  },
}
</script>




