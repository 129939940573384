<template>
    <div>
        <NavList/>
        <div class="confirmation_box">
            <div class="confirmation_text">
                <p class="comif_p">🎉</p>
                <h2 class="comif_h2">Thank you!</h2>
                <p class="comif_Stay">Stay tuned with our latest updates and let's build together!</p>
            </div>
        </div>
        <Footer/>
    </div>
</template>
<style scoped>
.confirmation_box {
    height: 944PX;
    display: flex;
    align-items: center;
    justify-content: center;
}
.confirmation_text {
    width: 380PX;
    text-align: center;
}
.comif_p {
    font-size: 64PX;
}
.comif_h2 {
    font-size: 44PX;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 600;
    color: #333333;
}
.comif_Stay {
    font-size: 18PX;
    font-family: Montserrat-Regular, Montserrat;
    font-weight: 400;
    color: #333333;
}
</style>
<script>
import Footer from '../pc/footer.vue'
import NavList from './navlist.vue'

export default {
  name: "confirmation",
  components: {Footer,NavList},

  setup() {
    return {};
  },

  props: {
    msg: String,
  },
  mounted() {
    // window.addEventListener("scroll", this.showbtn, true);
  },
  methods: {
    // 回到顶部
    backTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 10);
    },
  },
};
</script>