<template>
  <div class="footer_box" id="toContact">
    <div class="footer_auto justify-end">
      <div class="footer_margin_left"> 
        <div class="footer_left backgr" @click="backTop"></div>
        <div class="footer_address">
          <a href="https://twitter.com/BytetradeLab">
            <div class="footer_fockbook backgr"></div>
          </a>
          <a href="https://medium.com/@bytetradelab">
            <div class="footer_addclone backgr"></div>
          </a>
        </div>
      </div>
      <div class="subscribe_newsletter">
        <p class="subscribe_newsletter_p">Subscribe Newsletter</p>
        <div id="mc_embed_signup">
          <form 
            action="https://app.sendx.io/api/v1/form/9b5eAFz1LmyKbKUbd1EspB" 
            method="post" 
            ref="formRef" 
            target="form" 
            id="mc-embedded-subscribe-form" 
            class="validate"
            @submit.prevent="onSubmit"
          >
                <div id="mc_embed_signup_scroll">
                <div class="mc-field-group">
                  <input type="Email" v-model="inputTxt" name="Email" class="required email" placeholder="Type your e-mail here" id="mce-EMAIL" required>
                  <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
                  <input type="submit" value="" id="mc-embedded-subscribe" class="button">
                  <div class="button_btn"></div>
                </div>
              </div>
          </form>
          <iframe name="form" id="form" ref="iframeRef" style="display: none;" onload=""></iframe>
        </div>

      </div>
    </div>
  </div>
</template>
<style scoped>
.footer_box {
  width: 100%;
  height: 337PX;
  background: #ec4b3d;
}
.backgr {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.footer_auto {
  width: 1200PX;
  height: 100%;
  margin: 0 auto;
  align-items: center;
}
.footer_left {
  width: 169PX;
  height: 32PX;
  
  background-image: url("../../assets/byteteade_logo.png");
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.footer_margin_left {
  margin-right: auto;
}
.footer_left:hover {
  transform: translateY(-10PX);
  -webkit-transform: translateY(-10PX);
  -moz-transform: translateY(-10PX);
  -o-transform: translateY(-10PX);
  -ms-transform: translateY(-10PX);
}
.footer_address {
  display: flex;
  margin-top: 38PX;
}
.footer_fockbook,
.footer_addclone {
  width: 58PX;
  height: 58PX;
  cursor: pointer;
}

.footer_fockbook {
  background-image: url("../../assets/fackbook.png");
  margin-right: 38PX;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.footer_fockbook:hover {
  transform: translateY(-12PX);
  -webkit-transform: translateY(-12PX);
  -moz-transform: translateY(-12PX);
  -o-transform: translateY(-12PX);
  -ms-transform: translateY(-12PX);
  background-image: url("../../assets/fackbook_hover.png");
}

.footer_addclone {
  background-image: url("../../assets/clone.png");
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.footer_addclone:hover {
  transform: translateY(-12PX);
  -webkit-transform: translateY(-12PX);
  -moz-transform: translateY(-12PX);
  -o-transform: translateY(-12PX);
  -ms-transform: translateY(-12PX);
  background-image: url("../../assets/clone_hover.png");
}
.subscribe_newsletter_p {
  font-size: 28PX;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #FFFFFF;
}
.required {
  width: 460PX;
  height: 64PX;
  border: 1px solid #FFFFFF;
  font-size: 18PX;
  font-family: Montserrat-Regular, Montserrat;
  font-weight: 400;
  color: #FFFFFF;
  padding-left: 16px;
}
.required::-webkit-input-placeholder{
  color: #FFFFFF;
  opacity: 60%;
}
.required::-moz-placeholder{   
  color: #FFFFFF;
  opacity: 60%;
}
.required:-moz-placeholder{    
  color: #FFFFFF;
  opacity: 60%;
}
.required:-ms-input-placeholder{  
  color: #FFFFFF;
  opacity: 60%;
}
.button {
  width: 88PX;
  height: 64PX;
  position: absolute;
  top: 0PX;
  right: 0PX;
  background-image: url('../../assets/Subscribe.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 99;
  opacity: 0;
  cursor: pointer;
  
}
.button_btn {
  width: 88PX;
  height: 64PX;
  position: absolute;
  top: 0PX;
  right: 0PX;
  background-image: url('../../assets/Subscribe.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.mc-field-group {
  position: relative;
  top: 32PX;
}
</style>
<script>
import { ref, onMounted } from "vue";
import { message } from 'ant-design-vue';

export default {
  name: "footer",
  components: {},

  setup() {
    const iframeRef = ref(null);
    const inputTxt = ref(null);
    const formRef = ref(null);
    const isSubmit = ref(false);
    const key = 'Subscribing';

    const onSubmit = () => {
      if (!inputTxt.value) return false;
      if (isSubmit.value) {
        return false;
      } else {
        isSubmit.value = true;
      }
      
      formRef.value.submit();
      message.loading({ content: 'Subscribing...', key, duration: 600});
    }

    const checkIframe = () => {
      if (!isSubmit.value) {
        return false;
      } else {
        isSubmit.value = false;
      }
      message.success({ content: 'You have successfully subscribed to our newsletter :)', key, duration: 2 });
      inputTxt.value = '';
    }

    onMounted(() => {
      if (iframeRef.value.attachEvent) {
        // IE
        iframeRef.value.attachEvent('onload', () => {
          checkIframe();
        });
      } else {
        // not IE
        iframeRef.value.onload = function () {
          checkIframe();
        };
      }
    })

    
    return {
      iframeRef,
      inputTxt,
      formRef,
      onSubmit
    };
  },

  props: {
    msg: String,
  },
  mounted() {
    // window.addEventListener("scroll", this.showbtn, true);
  },
  methods: {
    // 回到顶部
    backTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 10);
    }
  },
};
</script>
