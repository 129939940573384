<template>
  <div>
    <Nav/>
    <Explain/>
    <Content/>
    <Tips/>
    <ContentBottom/>
    <Events/>
    <Research />
    <Footer/>
  </div>
</template>

<script>
import { ref } from 'vue';
import Explain from './explain.vue'
import Nav from './nav.vue'

import Content from './content.vue'
import Tips from './tips.vue'
import ContentBottom from './contentBottom.vue'
import Events from './events.vue'

import Footer from './footer.vue'
import Research from './research.vue'


import '../../style/common.css'
import '../../style/home.css';
export default {
  name: 'home',
  components: {
    Content,
    Explain,
    Nav,
    Tips,
    ContentBottom,
    Footer,
    Events,
    Research
  },
  
  setup() {
    let dataList = ref([
      {
        picture:'picture',
        name:'name',
        time:'time',
        citv:'citv',
        address:'address',
        organizer:'organizer',
        status_live:'status: live'
      },
      {
        picture:'picture',
        name:'name',
        time:'time',
        citv:'citv',
        address:'address',
        organizer:'organizer',
        status_live:'status: live'
      },
      {
        picture:'picture',
        name:'name',
        time:'time',
        citv:'citv',
        address:'address',
        organizer:'organizer',
        status_live:'status: live'
      },
      {
        picture:'picture',
        name:'name',
        time:'time',
        citv:'citv',
        address:'address',
        organizer:'organizer',
        status_live:'status: live'
      }
    ]
    )
    return {
      dataList,
      // eventbrite
    }
  },
  created(){
  },
  props: {
    msg: String
  }
}
</script>

